var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "component_popup",
      attrs: {
        visible: _vm.visible,
        width: _vm.width,
        "before-close": () => _vm.$emit("close"),
        title: _vm.isSlot ? _vm.title : "",
        center: "",
        "modal-append-to-body": false,
        "close-on-click-modal": _vm.modelClose,
        "show-close": _vm.showClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.isSlot
        ? _c(
            "div",
            { staticClass: "component_popup_content" },
            [_vm._t("default")],
            2
          )
        : _c(
            "div",
            { staticClass: "flex-column-center popup_common" },
            [
              _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c("p", {
                staticClass: "msg",
                class: _vm.msgAlign,
                domProps: { innerHTML: _vm._s(_vm.msg) },
              }),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn,
                      expression: "btn",
                    },
                  ],
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("confirm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.btn))]
              ),
              _vm.tips
                ? _c("p", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.tips))])
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }