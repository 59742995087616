<template>
    <el-dialog
        :visible.sync="visible"
        :width="width"
        :before-close="() => $emit('close')"
        class="component_popup"
        :title="isSlot ? title : ''" center
        :modal-append-to-body="false"
        :close-on-click-modal="modelClose"
        :show-close="showClose"
    >
        
        <div v-if="isSlot" class="component_popup_content">
            <slot></slot>
        </div>
        
        <div v-else class="flex-column-center popup_common">
            <p class="title">{{ title }}</p>
            <p class="msg" :class="msgAlign" v-html="msg"></p>
            <el-button @click="$emit('confirm')" class="btn" v-show="btn">{{ btn }}</el-button>
            <p class="tips" v-if="tips">{{ tips }}</p>
        </div>

    </el-dialog>
</template>

<script>
import { isMobile } from '@/utils';
import { Button, Dialog } from 'element-ui';

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
          default: isMobile() ? '90%' : '600px',
        },
        title: {
            type: String,
            default: '',
        },
        msg: {
            type: String,
            default: '',
        },
        btn: {
            type: String,
            default: '',
        },
        msgAlign: {
            type: String,
            default: 'left'
        },
        tips: {
            type: String,
            default: null
        },
        modelClose: {
            type: Boolean,
            default: true
        },
        showClose:{
          type: Boolean,
          default: true
        }
    },
    computed: {
        isSlot() {
            return !!(this.$slots.default && this.$slots.default.length > 0)
        },
        visible: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    watch: {
        visible(val) {
            val && this.$emit('init')
        }
    }
}
</script>

<style lang="scss">
.component_popup {
    .el-dialog {
        border-radius: 10px !important;
    }
    .msg {
        &.left {
            text-align: left;
        }
        &.center {
            text-align: center;
        }
        &.right {
            text-align: right;
        }
    }
    .el-dialog__body {
        padding: 30px 15px 30px 20px;
    }
    .el-dialog__headerbtn {
        font-size: 28px;
    }
    .component_popup_content {
        max-height: 500px;
        overflow-y: auto;
        padding-right: 5px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: gray;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #ccc;
        }
    }
}
</style>
